import { Player } from "@lottiefiles/react-lottie-player";
import styles from "./index.module.css";

export default (props: Partial<Player>) => {
  return (
    <div className={styles.container}>
      <Player
        autoplay
        loop
        src={"/animations/loading.json"}
        style={{ height: "200px", width: "200px" }}
        {...props}
      />
    </div>
  );
};
